import { DatePicker, Form, Table, notification, Button } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { DownloadOutlined, ReloadOutlined } from '@ant-design/icons';
import { PaymentStatus } from '../../components/common/userTypes';
import { GetInvoiceReceipt } from '../../container/actions/Subscription/SubscriptionHistoryApi';
import { getPaymentStatusLabel, getSubscriptionStatusLabel } from '../../utils/ConstLabel';
import { selectUserDetails } from '../../container/reducers/slices/UserOverview/UserOverviewSlice';
import {
  getUserSubscriptionHistoryAsync,
  selectUserSubscriptionHistory,
  selectUserSubscriptionHistoryLoading,
} from '../../container/reducers/slices/UserSubscriptionHistory/UserSubscriptionHistorySlice';
import { useParams } from 'react-router-dom';

const UserSubscriptionHistory = () => {
  const dispatch = useDispatch();
  const [filterForm] = Form.useForm();
  const [filters, setFilters] = useState(null);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    defaultPageSize: 10,
    showSizeChanger: true,
  });
  const [loading, setLoading] = useState(false);

  const userInfo = useSelector(selectUserDetails);

  const { id } = useParams();

  const subscriptionData = useSelector(selectUserSubscriptionHistory);
  const subscriptionDataLoading = useSelector(selectUserSubscriptionHistoryLoading);

  // Fetch data when filters or pagination change
  useEffect(() => {
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, pagination, dispatch]);

  const fetchData = async () => {
    const params = {
      ...filters,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      id,
      startDate: filters?.startDate ? dayjs(filters?.startDate).format('YYYY-MM-DD') : null,
      endDate: filters?.endDate ? dayjs(filters?.endDate).format('YYYY-MM-DD') : null,
    };
    dispatch(getUserSubscriptionHistoryAsync(params));
  };

  const startDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });

    if (value === null) {
      setFilters({ ...filters, startDate: null });
      return;
    }

    const newStartDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
    setFilters({ ...filters, startDate: newStartDate });
  };

  const endDateChange = (value) => {
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10, // Ensure the pageSize is reset here
    });

    if (value === null) {
      setFilters({ ...filters, endDate: null });
      return;
    }

    const newEndDate = value ? dayjs(value).format('YYYY-MM-DD') : null;
    setFilters({ ...filters, endDate: newEndDate });
  };

  const handleTableChange = (pagination) => {
    setPagination(pagination);
  };

  const getInvoiceReceipt = async (id) => {
    try {
      setLoading(true);
      const { result, error } = await GetInvoiceReceipt(id, userInfo?.id);

      if (!error && result.success === true) {
        window.open(result.result.receipt_path, '_blank');
      } else {
        notification.error({
          message: 'Failure',
          description: 'Something went wrong!',
        });
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error occurred while fetching signed URL:');
    }
  };

  const columns = [
    {
      title: 'Invoice Number',
      dataIndex: 'invoice_no',
      key: 'invoice_no',
      render: (_text, record) => {
        return record?.payment_status === PaymentStatus.PAYMENT_FREE_TRIAL ? '-' : record?.invoice_no ? record?.invoice_no : '-';
      },
    },
    {
      title: 'Start Date',
      dataIndex: 'start_date',
      key: 'start_date',
      render: (_text, record) => {
        return record?.start_date ? dayjs(record?.start_date).format('DD/MM/YYYY') : '-';
      },
    },
    {
      title: 'Due Date',
      dataIndex: 'end_date',
      key: 'end_date',
      render: (_text, record) => {
        return record?.end_date ? dayjs(record?.end_date).format('DD/MM/YYYY') : '-';
      },
    },
    {
      title: 'Paid by',
      dataIndex: 'paid_by',
      key: 'paid_by',
      render: (_text, record) => {
        return record?.created_by_user_name || '-';
      },
    },
    {
      title: 'Payment Status',
      dataIndex: 'payment_status',
      key: 'payment_status',
      render: (text, record) => <span className="d-flex justify-content-center">{getPaymentStatusLabel(text)}</span>,
    },
    {
      title: 'Subscription Status',
      dataIndex: 'subscription_status',
      key: 'subscription_status',
      render: (text) => <span className="d-flex justify-content-center">{text ? getSubscriptionStatusLabel(Number(text)) : '-'}</span>,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_text, record) => {
        return (
          <div>
            {record?.invoice_no && <Button title="Download Invoice" icon={<DownloadOutlined />} type="primary" size="large" onClick={() => getInvoiceReceipt(record.subscription_payment_id)} />}

            {!record?.invoice_no && '-'}
          </div>
        );
      },
    },
  ];

  const resetFilter = () => {
    filterForm.resetFields();
    setFilters({
      ...filters,
      skip: (Number(pagination.current) - 1) * pagination.pageSize,
      limit: pagination.pageSize,
      id: userInfo?.id,
      startDate: null,
      endDate: null,
    });
    setPagination({
      ...pagination,
      current: 1,
      pageSize: 10,
    });
  };

  return (
    <>
      <div className="d-flex gap-3 align-items-center flex-sm-column loans-header-responsive flex-md-row flex-lg-row justify-content-end">
        <div className="date_picker reset-width-100">
          <Form form={filterForm}>
            <div className="d-flex loans-header-responsive justify-content-end align-items-center gap-3">
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="startDate" className="p-0 m-0">
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={startDateChange}
                      disabledDate={(date) => {
                        const toDate = filterForm.getFieldValue(['endDate']);
                        return date && toDate && date > dayjs(toDate);
                      }}
                      style={{ width: '100%', background: 'transparent' }}
                      placeholder="Start Date"
                    />
                  </Form.Item>
                </div>
              </div>

              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Form.Item name="endDate" className="p-0 m-0">
                    <DatePicker
                      format="DD-MM-YYYY"
                      onChange={endDateChange}
                      style={{ width: '100%', background: 'transparent' }}
                      disabledDate={(date) => {
                        const fromDate = filterForm.getFieldValue(['startDate']);
                        return date && fromDate && date < dayjs(fromDate);
                      }}
                      placeholder="End Date"
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="reset-width-100">
                <div className="mb-3 mb-lg-0">
                  <Button icon={<ReloadOutlined />} type="primary" size="large" className="reset-width-100 d-flex align-items-center" onClick={resetFilter}>
                    Reset
                  </Button>
                </div>
              </div>
            </div>
          </Form>
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={columns}
              pagination={{ ...pagination, total: subscriptionData?.total || 0, showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items` }}
              dataSource={subscriptionData?.data || []}
              scroll={{ x: 'max-content' }}
              loading={loading || subscriptionDataLoading}
              onChange={handleTableChange}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default UserSubscriptionHistory;
