import React, { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, notification, Popconfirm, Row, Select, Spin } from 'antd';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteOutlined, LeftOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import { OCRStatus, PropertyType } from '../../components/common/userTypes';
import { GetPropertyListWithOutLoan } from '../../container/actions/RentSummary/getPropertyListWithOutLoan.action';
import { GetCategoryList } from '../../container/actions/settlenents/getCategoryList.action';
import { GetRentalSummaryById, ResetRentalSummaryTransection } from '../../container/actions/RentSummary/getRentalSummaryById.action';
import { EditRecordExpense } from '../../container/actions/RentSummary/editRecordExpense.action';
import { GetRentalSummaryTransectionById } from '../../container/actions/RentSummary/getRentbyTransectionId.action';
import { AddRentalSummary } from '../../container/actions/RentSummary/addRentalSummary.action';
import { DeleteSettlementList } from '../../container/actions/RentSummary/deleteRentalSummary.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { getLockYearsList } from '../../container/actions/AuditLock';
import { getErrorMessageForLockedYear, getFinancialYearRanges } from '../../utils';
import isBetween from 'dayjs/plugin/isBetween'; // load on demand
const { Option } = Select;
const text = 'Are you sure you want to discard?';
dayjs.extend(isBetween);

export default function RentalSummary() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [rentalSummaryForm] = Form.useForm();
  const { state } = useLocation();

  const [loading, setLoading] = useState(true);
  const [propertyData, setPropertyData] = useState([]);
  const [categoryData, setCategoryData] = useState([]);
  const [rSummaryData, setRSummaryData] = useState({});
  const [incomeCategorydata, setIncomeCategorydata] = useState([]);
  const [expenseCategorydata, setExpenseCategorydata] = useState([]);
  const [costCategorydata, setCostCategorydata] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);

  const propertyDta = useSelector((state) => state.getPropertyListWithOutLoanSliceReducer);
  const categoryDta = useSelector((state) => state.getCategoryListReducer);
  const rSummaryDta = useSelector((state) => state.getRentalSummaryByIdSliceReducer);
  const rSummaryDta1 = useSelector((state) => state.getRentalSummaryTransectionByIdSliceReducer);

  useEffect(() => {
    setLoading(true);
    dispatch(GetPropertyListWithOutLoan(state?.UserId));
    dispatch(GetCategoryList());
    if (state?.completed) {
      dispatch(GetRentalSummaryTransectionById(id, state?.UserId));
    } else if (state?.completed === false) {
      dispatch(GetRentalSummaryById(id, state?.UserId));
    }
    getLockYears();
  }, [id, dispatch, state?.UserId, state?.completed]);

  // state blank (UnMount before calling)
  useEffect(() => {
    return () => {
      dispatch(ResetRentalSummaryTransection());
    };
  }, [dispatch]);

  useEffect(() => {
    if (propertyDta?.isLoading && categoryDta?.isLoading && rSummaryDta?.isLoading && rSummaryDta1?.isLoading) {
      setLoading(true);
    }
    if (propertyDta && propertyDta?.data?.result && propertyDta?.error === false) {
      let dt = propertyDta?.data?.result?.map((elm) => {
        return {
          value: elm.id,
          label: elm.street_number ? elm.street_number + (elm.street_name ? ' - ' + elm.street_name : '') : '',
          disabled: elm.property_type === PropertyType.OWNER_OCCUPIED_PROPERTY,
          propertyType: elm.property_type,
        };
      });

      setPropertyData(dt);
    }
    if (categoryDta && categoryDta?.data.result && categoryDta?.error === false) {
      if (categoryDta?.data.result) {
        let dt = categoryDta?.data.result.map((elm) => {
          return {
            value: elm.id,
            label: elm.category_name,
            income: !!(elm.expense_type === 'Revenue' && elm.transaction_type === 'In'),
            expense: !!(elm.expense_type === 'Revenue' && elm.transaction_type === 'Out'),
            cost: elm.expense_type === 'Capital',
          };
        });
        setCategoryData(dt);
        let Incom = dt.filter((item) => ![false].includes(item.income));
        setIncomeCategorydata(Incom);
        let Expense = dt.filter((item) => ![false].includes(item.expense));
        setExpenseCategorydata(Expense);
        let Cost = dt.filter((item) => ![false].includes(item.cost));
        setCostCategorydata(Cost);
        let dtCat = categoryDta?.data.result.filter((elm) => [1, 8].includes(elm.id));
        let dtFinal = dtCat?.map((elm) => {
          return { category_id: elm.id, amount: '' };
        });
        rentalSummaryForm.setFieldsValue({ categories: dtFinal || [] });
      }
    }
    if (state?.completed) {
      if (rSummaryDta1 && rSummaryDta1?.data.result && rSummaryDta1?.error === false && propertyDta && propertyDta?.data.result && propertyDta?.error === false) {
        setLoading(true);
        if (rSummaryDta1?.data.result) {
          const PROPERTYTYPE = propertyDta?.data?.result?.find((x) => x.id === rSummaryDta1?.data?.result?.property_id);
          setRSummaryData(rSummaryDta1?.data.result);
          if (rSummaryDta1?.data.result.property_id && rSummaryDta1?.data.result.property_id !== null && rSummaryDta1?.data.result.property_id !== undefined) {
            rentalSummaryForm.setFieldsValue({ property_id: rSummaryDta1?.data.result.property_id || '' });
          }
          if (rSummaryDta1?.data.result.categories && rSummaryDta1?.data.result.categories.length !== 0) {
            let dtFinal = rSummaryDta1?.data.result.categories.map((elm) => {
              return { category_id: elm.category_id, amount: elm.amount, id: elm.id };
            });
            rentalSummaryForm.setFieldsValue({ categories: dtFinal || [] });
            setLoading(false);
          }
          rentalSummaryForm.setFieldsValue({ rent_start_date: rSummaryDta1?.data?.result?.rent_start_date ? dayjs(rSummaryDta1?.data?.result?.rent_start_date) : '' });
          rentalSummaryForm.setFieldsValue({ rent_end_date: rSummaryDta1?.data?.result?.rent_end_date ? dayjs(rSummaryDta1?.data?.result?.rent_end_date) : '' });
          rentalSummaryForm.setFieldsValue({ transaction_date: rSummaryDta1?.data?.result?.transaction_date ? dayjs(rSummaryDta1?.data?.result?.transaction_date) : '' });
          rentalSummaryForm.setFieldsValue({
            property_type: getPropertyTypeLabel(PROPERTYTYPE?.property_type),
          });
          setLoading(false);
        }
      }
    } else {
      if (rSummaryDta && rSummaryDta?.data.result && rSummaryDta?.error === false) {
        setRSummaryData(rSummaryDta?.data.result);
        if (rSummaryDta.data.result.ocr_status === OCRStatus.OCR_REVIEW) {
          if (rSummaryDta?.data.result.property_id && rSummaryDta?.data.result.property_id !== null && rSummaryDta?.data.result.property_id !== undefined) {
            rentalSummaryForm.setFieldsValue({ property_id: rSummaryDta?.data.result.property_id || '' });
          }
          if (rSummaryDta?.data.result.categories && rSummaryDta?.data.result.categories.length !== 0) {
            let dtFinal = rSummaryDta?.data.result.categories.map((elm) => {
              return { category_id: elm.category_id, amount: elm.amount, id: elm.id };
            });
            rentalSummaryForm.setFieldsValue({ categories: dtFinal || [] });
          }
          const PROPERTYTYPE = propertyDta?.data?.result?.find((x) => x.id === rSummaryDta?.data?.result?.property_id);
          if (PROPERTYTYPE && PROPERTYTYPE?.property_type) {
            rentalSummaryForm.setFieldsValue({
              property_type: getPropertyTypeLabel(PROPERTYTYPE?.property_type),
            });
          }

          rentalSummaryForm.setFieldsValue({ rent_start_date: rSummaryDta?.data?.result?.rent_start_date ? dayjs(rSummaryDta?.data?.result?.rent_start_date) : '' });
          rentalSummaryForm.setFieldsValue({ rent_end_date: rSummaryDta?.data?.result?.rent_end_date ? dayjs(rSummaryDta?.data?.result?.rent_end_date) : '' });
          rentalSummaryForm.setFieldsValue({ transaction_date: rSummaryDta?.data?.result?.transaction_date ? dayjs(rSummaryDta?.data?.result?.transaction_date) : '' });
          setLoading(false);
        }
        setLoading(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propertyDta, categoryDta, rSummaryDta, rSummaryDta1]);

  const getLockYears = () => {
    dispatch(getLockYearsList(state?.UserId)).then((data) => {
      if (data.success) {
        setFinancialYears(data.result || []);
      }
    });
  };

  const validateDate = (date) => {
    const ranges = getFinancialYearRanges(financialYears);
    for (let range of ranges) {
      if (range.locked && date && date.isBetween(range.start, range.end, 'day', '[]')) {
        return getErrorMessageForLockedYear(date, range.locked_user_type, range.locked_by);
      }
    }
    return null;
  };

  const handleFinish = (values) => {
    const sendCategory = values?.categories.map((elm) => {
      return { categoryId: elm.category_id, amount: elm.amount, id: elm.id };
    });
    if (state?.completed === false) {
      try {
        setLoading(true);
        values = {
          propertyId: values.property_id,
          categoryArray: sendCategory,
          rentSummaryId: id,
          endDate: values.rent_end_date.format('YYYY-MM-DD'),
          startDate: values.rent_start_date.format('YYYY-MM-DD'),
          transactionDate: values.transaction_date.format('YYYY-MM-DD'),
        };

        dispatch(AddRentalSummary(values, state?.UserId)).then((data) => {
          if (data.payload.success === true) {
            notification.success({
              message: 'Success',
              description: data.payload.message,
            });
            rentalSummaryForm.resetFields();
            setLoading(false);
            navigate('/rent/pending');
          } else {
            notification.error({
              message: 'Failure',
              description: data.payload.message || 'Something went wrong!',
            });
            setLoading(false);
          }
        });
      } catch (error) {}
    }
    if (rSummaryData?.ocr_status !== OCRStatus.OCR_REVIEW) {
      try {
        setLoading(true);
        values = {
          propertyId: values.property_id,
          categories: sendCategory,
          rentEndDate: values.rent_end_date.format('YYYY-MM-DD'),
          rentStartDate: values.rent_start_date.format('YYYY-MM-DD'),
          transactionDate: values.transaction_date.format('YYYY-MM-DD'),
        };
        dispatch(EditRecordExpense(values, rSummaryData?.categories[0]?.id, state?.UserId)).then((data) => {
          if (data.payload.success === true) {
            notification.success({
              message: 'Success',
              description: data.payload.message,
            });
            rentalSummaryForm.resetFields();
            setLoading(false);
            navigate(`/rent/${state?.completed ? 'complete' : 'pending'}`);
          } else {
            notification.error({
              message: 'Failure',
              description: data.payload.message || 'Something went wrong!',
            });
            setLoading(false);
          }
        });
      } catch (error) {}
    }
  };

  const discardRecord = () => {
    setLoading(true);
    dispatch(DeleteSettlementList(id, state?.UserId, state?.completed)).then((response) => {
      if (response?.payload?.success) {
        notification.success({
          message: 'Success',
          description: response.payload.message || 'Rental summary deleted!',
        });
        navigate(`/rent/${state?.completed ? 'complete' : 'pending'}`);
        setLoading(false);
      } else {
        notification.error({
          message: 'Failure',
          description: response.payload.message || 'Something went wrong!',
        });
        setLoading(false);
      }
    });
  };
  const handlePropertyChange = (e, data) => {
    rentalSummaryForm.setFieldsValue({
      property_type: getPropertyTypeLabel(data?.propertyType),
    });
  };

  const handleClick = () => {
    if (state?.completed) {
      navigate('/rent/complete');
    } else {
      navigate('/rent/pending');
    }
  };

  return loading ? (
    <Spin style={{ width: '100%', height: '80vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }} spinning={true}>
      <span style={{ opacity: 0 }}>pm</span>
    </Spin>
  ) : (
    <div className="">
      <Row>
        <Button className="btn_goBack" onClick={() => handleClick()} icon={<LeftOutlined />} />
        <h5 className="m-0">Rental Summary</h5>
      </Row>

      <div className="faq_wrapper1">
        <Form name="addProductForm" form={rentalSummaryForm} layout="vertical" onFinish={handleFinish} autoComplete="off" wrapperCol={{ span: 24 }}>
          <div className="row">
            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div id="Iframe-Master-CC-and-Rs" className="set-margin set-padding set-border set-box-shadow center-block-horiz">
                    <div className="responsive-wrapper responsive-wrapper-wxh-572x612">
                      <iframe title="nothing" src={rSummaryData?.receipt_path}></iframe>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-xl-6 col-md-6 col-12 mt-3">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-12 col-12">
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Select Property"
                            name="property_id"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Property!',
                              },
                            ]}
                          >
                            <Select
                              style={{ width: '100%', textAlign: 'left' }}
                              placeholder="Select Property"
                              showSearch
                              options={propertyData}
                              onChange={(e, data) => handlePropertyChange(e, data)}
                              optionFilterProp="children"
                              filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                            >
                              <Option value="" key={crypto.randomUUID()}>
                                - - - Select Property - - -
                              </Option>
                            </Select>
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="Statement Date"
                            name="transaction_date"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Statement Date!',
                              },
                              () => ({
                                validator(_, value) {
                                  const errorMessage = validateDate(value);
                                  if (!value || !errorMessage) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(new Error(errorMessage));
                                },
                              }),
                            ]}
                          >
                            <DatePicker format="DD-MM-YYYY" style={{ background: 'transparent', width: '100%' }} className="form-control" />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={12}>
                          <Form.Item
                            label="Start Date"
                            name="rent_start_date"
                            rules={[
                              {
                                required: true,
                                message: 'Please select Start Date!',
                              },
                            ]}
                          >
                            <DatePicker
                              format="DD-MM-YYYY"
                              style={{ background: 'transparent', width: '100%' }}
                              className="form-control"
                              disabledDate={(date) => {
                                return date && rentalSummaryForm?.getFieldValue('rent_end_date') && date > dayjs(rentalSummaryForm?.getFieldValue('rent_end_date'));
                              }}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            label="End Date"
                            name="rent_end_date"
                            rules={[
                              {
                                required: true,
                                message: 'Please select End Date!',
                              },
                            ]}
                          >
                            <DatePicker
                              format="DD-MM-YYYY"
                              style={{ background: 'transparent', width: '100%' }}
                              className="ml-1 form-control"
                              disabledDate={(date) => {
                                return date && rentalSummaryForm?.getFieldValue('rent_start_date') && date < dayjs(rentalSummaryForm?.getFieldValue('rent_start_date'));
                              }}
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <div className="col-xl-6 col-md-6 col-12">
                        <Form.Item label="Property Type" name="property_type">
                          <Input className="form-control p-1 px-2" style={{ color: 'black' }} disabled={true} placeholder="Property Type" type="text" />
                        </Form.Item>
                      </div>
                    </div>
                  </div>

                  <div className="row justify-content-center mt-1 rental_wrapper">
                    <div className="col-12">
                      <div className="card">
                        <div className="card-header d-flex justify-content-between p-4">
                          <h6 className="m-0">Details</h6>
                          <h6 className="m-0">Amount</h6>
                        </div>
                        <div className="card-body p-4" style={{ maxHeight: '600px', overflowY: 'scroll' }}>
                          <Form.List name="categories">
                            {(fields, { add, remove }) => (
                              <div className="col-md-12 p-0">
                                {fields?.map(({ key, name, ...restField }) => (
                                  <Row gutter={24} key={crypto.randomUUID()}>
                                    <Col span={12}>
                                      <Form.Item
                                        className="p-0 m-0"
                                        {...restField}
                                        name={[name, 'category_id']}
                                        style={{ width: '100%' }}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please select Property type',
                                          },
                                        ]}
                                      >
                                        <Select
                                          placeholder="Property Type"
                                          style={{
                                            width: '100%',
                                          }}
                                          defaultActiveFirstOption={true}
                                          showSearch
                                          optionFilterProp="children"
                                          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input) || (option?.label.toUpperCase() ?? '').includes(input)}
                                          options={[
                                            {
                                              label: 'Income',
                                              options: incomeCategorydata,
                                            },
                                            {
                                              label: 'Expense',
                                              options: expenseCategorydata,
                                            },
                                            {
                                              label: 'Cost',
                                              options: costCategorydata,
                                            },
                                          ]}
                                        ></Select>
                                      </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                      <span></span>
                                    </Col>
                                    <Col span={8}>
                                      <Form.Item
                                        className="p-0 m-0"
                                        {...restField}
                                        style={{ width: '100%' }}
                                        name={[name, 'amount']}
                                        rules={[
                                          {
                                            required: true,
                                            message: 'Please enter Amount',
                                          },
                                        ]}
                                      >
                                        <Input
                                          style={{
                                            width: '100%',
                                          }}
                                          inputtype="number"
                                          className="form-control"
                                          placeholder="Amount"
                                          allowdecimal="true"
                                        />
                                      </Form.Item>
                                    </Col>
                                    <Col span={2}>
                                      {fields.length > 1 ? (
                                        <Form.Item className="delete_ico_hide">
                                          <Popconfirm
                                            title="Are you sure! You want to delete?"
                                            placement="topLeft"
                                            onConfirm={() => {
                                              remove(name);
                                            }}
                                          >
                                            <DeleteOutlined
                                              style={{
                                                fontSize: '1.3rem',
                                                color: 'red',
                                              }}
                                            />
                                          </Popconfirm>
                                        </Form.Item>
                                      ) : null}
                                    </Col>
                                  </Row>
                                ))}
                                <div className="text-center" style={{ padding: '1.4em' }}>
                                  <Button size="large" type="primary" onClick={() => add()}>
                                    + Add Category
                                  </Button>
                                </div>
                              </div>
                            )}
                          </Form.List>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-lg-between mt-4">
                      <Popconfirm placement="left" title={text} onConfirm={() => discardRecord()} okText="Yes" cancelText="No">
                        <Button danger ghost size="large">
                          Discard
                        </Button>
                      </Popconfirm>
                      <Button htmlType="submit" type="primary" size="large">
                        Save
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
}
