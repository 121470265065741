export const UserTypes = {
  USER: 1,
  TAXACCOUTANT: 11,
  SUB_TAXACCOUTANT: 12,
  ADMIN: 21,
  CHANNEL_PARTNER: 22,
  SUB_CHANNEL_PARTNER: 23,
  ADMIN_SUB_TAXACCOUNTANT: 13,
};

export const UserStatus = {
  ACTIVE: 1,
  DELETED: 0,
  COMPLETED: 4,

  PENDING: 3,
  CREATED: 5,
  CANCELED: 6,
  CANCELED_ACTIVE: 7,
};

export const BankAccountTypes = {
  'Individual Bank Account': 41,
  'Trust Bank Account': 42,
  'Joint Bank Account': 43,
  'Spouse Bank Account': 44,
};

// depreciation method
export const DepreciationMethod = {
  WDV_DEPRECIATION: 61,
  PRIME_COST_DEPRECIATION: 62,
};

export const settlementType = {
  PURCHASE_SETTLEMENT: 40,
  SELL_SETTLEMENT: 42,
};

export const PropertyType = {
  INVESTMENT_PROPERTY: 51,
  OWNER_OCCUPIED_PROPERTY: 52,
  COMMERCIAL_PROPERTY: 53,
};

export const tablePropertyType = {
  'Investment Property': 51,
  'Owner Occupied Property': 52,
  'Commercial Property': 53,
};

export const ChannelPartnerType = {
  MORTGAGE_BROKER: 81,
  CONVEYANCER: 82,
  RENTAL_PROPERTY_MANAGER: 83,
  BUYERS_AGENT: 85,
  OTHERS: 84,
};

// OCR Status
export const OCRStatus = {
  OCR_COMPLETE: 1,
  OCR_REVIEW: 2,
  OCR_PENDING: 3,
  OCR_FAILED: 0,
};

export const TransactionType = {
  RENT_TYPE_TRANSACTION: 31,
  SETTLEMENT_TYPE_TRANSACTION: 32,
  MANUAL_TYPE_TRANSACTION: 33,
  BASIQ_TYPE_TRANSACTION: 34,
  DEPRECIATION_SCHEDULE_TYPE_TRANSACTION: 35,
  SUBSCRIPTION_TYPE_TRANSACTION: 36,
};

export const NotificationType = {
  EMAIL: 1,
  SMS: 2,
  PUSH_NOTIFICATION: 3,
};

export const NotificationCategory = {
  Rental: 0,
  Settlement: 1,
  Depreciation: 2,
  Bank: 3,
  General: 4,
  Subscription: 5,
};

export const MFAType = {
  SMS_MFA: 78,
  TOTP_MFA: 79,
  EMAIL_MFA: 80,
};

export const DiscountType = {
  PERCENTAGE: 1,
  AMOUNT: 2,
};

export const UserActivityType = {
  ACTIVITY_PROPERTY_INSERT: 91,
  ACTIVITY_DEPRECIATIONS_INSERT: 92,
  ACTIVITY_SETTLEMENT_INSERT: 93,
  ACTIVITY_BANK_CONNECT: 94,
  ACTIVITY_RENT_INSERT: 95,
  ACTIVITY_REPORT_GENERATED: 96,
  ACTIVITY_EXPENSE_INSERT: 97,
  ACTIVITY_PROPERTY_DELETE: 98,
  ACTIVITY_DEPRECIATIONS_DELETE: 99,
  ACTIVITY_SETTLEMENT_DELETE: 100,
  ACTIVITY_RENT_DELETE: 101,
  ACTIVITY_EXPENSE_DELETE: 102,
  ACTIVITY_PROPERTY_EDIT: 103,
  ACTIVITY_DEPRECIATIONS_EDIT: 104,
  ACTIVITY_SETTLEMENT_EDIT: 105,
  ACTIVITY_RENT_EDIT: 106,
  ACTIVITY_EXPENSE_EDIT: 107,
  ACTIVITY_BANK_DISCONNECT: 108,
  ACTIVITY_SUBSCRIPTION_PURCHASE: 109,
  ACTIVITY_SUBSCRIPTION_CANCELED: 110,
};

export const MVUpdateBy = {
  ADMIN: 1,
  DOMAIN: 2,
};

export const PaymentStatus = {
  PAYMENT_COMPLETE: 85,
  PAYMENT_PENDING: 86,
  PAYMENT_FAILED: 87,
  PAYMENT_UNPAID: 88,
  PAYMENT_FREE_TRIAL: 89,
  PAYMENT_CHARGE_FAILED: 90,
};
