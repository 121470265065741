import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserSubscriptionHistory } from '../../../actions/UserSubscriptionHistory/UserSubscriptionHistoryApi';

const initialState = {
  error: false,
  isLoading: true,
  data: [],
};

export const getUserSubscriptionHistoryAsync = createAsyncThunk('userSubscriptionHistory/getHistory', async (obj) => {
  const response = await getUserSubscriptionHistory(obj);
  return response;
});

export const UserSubscriptionHistorySlice = createSlice({
  name: 'User Subscription History',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(getUserSubscriptionHistoryAsync.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserSubscriptionHistoryAsync.fulfilled, (state, action) => {
        state.data = action.payload.data;
        state.error = action.payload.error;
        state.isLoading = false;
      })
      .addCase(getUserSubscriptionHistoryAsync.rejected, (state, action) => {
        state.isLoading = false;
        state.error = true;
      });
  },
});

export const selectUserSubscriptionHistory = (state) => state.userSubscriptionHistory.data;
export const selectUserSubscriptionHistoryLoading = (state) => state.userSubscriptionHistory.isLoading;

export default UserSubscriptionHistorySlice.reducer;
