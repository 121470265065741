import React, { useEffect, useState } from 'react';
import { Badge, Button, Flex, Input, notification, Table, Tag } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PencilIcon } from '../../assets/icons';
import { GetCompleteDepreciation } from '../../container/actions/depreciation/getCompleteDepriciation.action';
import { getPropertyTypeLabel } from '../../utils/ConstLabel';
import { CheckCircleOutlined, CloseCircleOutlined, RedoOutlined, SyncOutlined } from '@ant-design/icons';
import { OCRStatus } from '../../components/common/userTypes';
import { DepreciationRegenerateOCR } from '../../container/actions/depreciation/getDepreciation.action';

const DepreciationCompleted = () => {
  const dispatch = useDispatch();
  const [searchText, setSearchText] = useState('');
  const [loading, setLoading] = useState(false);
  const [flattenedData, setFlattenedData] = useState([]);
  const { depreciationDatacomplete, isLoading } = useSelector((state) => state.depreciationCompleteDataReducer);

  const navigate = useNavigate();

  useEffect(() => {
    const savedSearch = JSON.parse(localStorage.getItem('depreciationCompleteFilter'));
    if (savedSearch) {
      setSearchText(savedSearch);
    }
  }, []);

  useEffect(() => {
    dispatch(GetCompleteDepreciation());
  }, [dispatch]);

  useEffect(() => {
    if (depreciationDatacomplete?.result && Array.isArray(depreciationDatacomplete.result)) {
      let flattened = [];
      depreciationDatacomplete.result.forEach((user) => {
        user.properties.forEach((property) => {
          flattened.push({
            ...property,
            email: user.email,
            virtual_email: user.virtual_email,
            property_name: `${property.street_number} ${property.street_name}, ${property.suburb}`,
          });
        });
      });

      if (searchText !== '') {
        flattened = flattened.filter((item) => {
          const email = item.email?.toLowerCase() || '';
          const vemail = item.virtual_email?.toLowerCase() || '';
          const propertyName = item.property_name?.toLowerCase() || '';
          return email.includes(searchText.toLowerCase()) || vemail.includes(searchText.toLowerCase()) || propertyName.includes(searchText.toLowerCase());
        });
      }
      setFlattenedData(flattened);
    }
  }, [depreciationDatacomplete, searchText]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchText(value);
    if (value === '') {
      localStorage.removeItem('depreciationCompleteFilter');
    }
  };

  const storeData = (record) => {
    navigate(`/depreciation/complete/${record?.depreciation?.id}`);
    localStorage.setItem('depreciationCompleteFilter', JSON.stringify(searchText));
  };

  const handleRegenerateOCR = (record) => async () => {
    setLoading(true);
    const id = record?.depreciation?.depreciation_main?.id;
    try {
      const res = await dispatch(DepreciationRegenerateOCR(id));
      if (res.success) {
        notification.success({
          message: 'Success',
          description: 'Request to regenerate OCR has been sent successfully',
        });
        await dispatch(GetCompleteDepreciation());
      } else {
        notification.error({
          message: 'Failure',
          description: 'Error in regenerating OCR',
        });
      }
    } catch (err) {
      notification.error({
        message: 'Failure',
        description: err.message || 'Error in regenerating OCR',
      });
    }
    setLoading(false);
  };

  const columns = [
    {
      title: 'User Email',
      dataIndex: 'email',
      key: 'email',
      width: '15%',
    },
    {
      title: 'User Unique ID',
      dataIndex: 'virtual_email',
      key: 'virtual_email',
      width: '20%',
    },
    {
      title: 'Property Name',
      dataIndex: 'property_name',
      key: 'property_name',
      width: '25%',
    },
    {
      title: 'Property Type',
      dataIndex: 'property_type',
      key: 'property_type',
      width: '10%',
      render: (text) => getPropertyTypeLabel(text),
    },
    {
      title: 'Status',
      key: 'status',
      width: '10%',
      render: (_, record) => (record.depreciation === null ? <Badge status="warning" text="Pending" /> : <Badge status="success" text="Completed" />),
    },

    {
      title: 'OCR Status',
      key: 'ocr_status',
      width: '10%',
      render: (_, record) => {
        if (record.depreciation === null) return '-';

        const ocrStatus = record?.depreciation?.depreciation_main?.ocr_status;
        switch (ocrStatus) {
          case OCRStatus.OCR_REVIEW:
            return (
              <Tag color="gold" icon={<CheckCircleOutlined />}>
                Review Pending
              </Tag>
            );
          case OCRStatus.OCR_PENDING:
            return (
              <Tag color="processing" icon={<SyncOutlined spin />}>
                In-progress
              </Tag>
            );
          case OCRStatus.OCR_FAILED:
            return (
              <Tag color="error" icon={<CloseCircleOutlined />}>
                Failed
              </Tag>
            );
          case OCRStatus.OCR_COMPLETE:
            return (
              <Tag color="success" icon={<CheckCircleOutlined />}>
                Success
              </Tag>
            );
          default:
            return '-';
        }
      },
    },
    {
      title: 'Action',
      key: 'action',
      width: '10%',
      render: (_, record) => {
        return record.depreciation === null ? (
          '-'
        ) : (
          <Flex gap="middle">
            <Button type="primary" onClick={() => storeData(record)} icon={<PencilIcon className="img-fluid" />} />
            {![OCRStatus.OCR_PENDING, OCRStatus.OCR_REVIEW].includes(record?.depreciation?.depreciation_main?.ocr_status) && (
              <Button type="primary" title="Regenerate OCR" icon={<RedoOutlined style={{ paddingTop: 3 }} />} onClick={handleRegenerateOCR(record)} />
            )}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      <div className="row align-items-center">
        <div className="col-xl-6 col-md-6 col-sm-6 col-12">
          <h5 className="m-0">Depreciation Completed</h5>
        </div>
        <div className="col-xl-6 col-md-6 col-sm-6 col-12 text-end custom-search-parent">
          <Input.Search placeholder="Search by Email, Unique ID, or Property Name" allowClear value={searchText} size="middle" onChange={handleSearchChange} className="custom-search" />
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="dashboard_list">
            <Table
              columns={columns}
              loading={isLoading || loading}
              pagination={{
                showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
              }}
              rowKey="id"
              dataSource={flattenedData}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default DepreciationCompleted;
