import { Tag } from 'antd';
import { ChannelPartnerType, MVUpdateBy, PaymentStatus, PropertyType, UserStatus, UserTypes } from '../components/common/userTypes';

export const getPropertyTypeLabel = (propertyType) => {
  switch (propertyType) {
    case PropertyType.COMMERCIAL_PROPERTY:
      return 'Commercial - Investment';
    case PropertyType.INVESTMENT_PROPERTY:
      return 'Residential - Investment';
    case PropertyType.OWNER_OCCUPIED_PROPERTY:
      return 'Residential - Owner Occupied';
    default:
      return '-';
  }
};

export const getMvUpdateTypeLabel = (mvUpdateType) => {
  switch (mvUpdateType) {
    case MVUpdateBy.ADMIN:
      return 'Admin';
    case MVUpdateBy.DOMAIN:
      return 'Domain';
    default:
      return '-';
  }
};

export const getUserTypeLabel = (userType) => {
  switch (userType) {
    case UserTypes.USER:
      return 'Property Investor';
    case UserTypes.TAXACCOUTANT:
      return 'Tax Accountant';
    case UserTypes.SUB_TAXACCOUTANT:
      return 'Sub Tax Accountant';
    case UserTypes.CHANNEL_PARTNER:
      return 'Channel Partner';
    case UserTypes.ADMIN:
      return 'Admin';
    case UserTypes.SUB_CHANNEL_PARTNER:
      return 'Sub Mortgage Broker';
    case UserTypes.ADMIN_SUB_TAXACCOUNTANT:
      return 'Admin Sub Tax Accountant';
    case ChannelPartnerType.MORTGAGE_BROKER:
      return 'Mortgage Broker';
    case ChannelPartnerType.CONVEYANCER:
      return 'Conveyancer';
    case ChannelPartnerType.RENTAL_PROPERTY_MANAGER:
      return 'Rental Property Manager';
    case ChannelPartnerType.BUYERS_AGENT:
      return 'Buyers Agent';
    case ChannelPartnerType.OTHERS:
      return 'Others';
    default:
      return '-';
  }
};

export const getSubscriptionStatusLabel = (status) => {
  switch (status) {
    case UserStatus.ACTIVE:
    case UserStatus.CANCELED_ACTIVE:
      return <Tag color="green">Active</Tag>;
    case UserStatus.COMPLETED:
      return <Tag color="red">Expired</Tag>;
    case UserStatus.CANCELED:
      return <Tag color="red">Cancelled</Tag>;
    default:
      return '-';
  }
};

export const getPaymentStatusLabel = (status) => {
  switch (status) {
    case PaymentStatus.PAYMENT_COMPLETE:
      return <Tag color="green">Paid</Tag>;
    case PaymentStatus.PAYMENT_PENDING:
      return <Tag color="blue">Processing</Tag>;
    case PaymentStatus.PAYMENT_FAILED:
    case PaymentStatus.PAYMENT_CHARGE_FAILED:
      return <Tag color="red">Failed</Tag>;
    case PaymentStatus.PAYMENT_FREE_TRIAL:
      return <Tag color="green">Free Trial</Tag>;
    default:
      return '-';
  }
};
