import { postRequest } from '../../../utils/apiUtils';

export const getUserSubscriptionHistory = (obj) => {
  const { id, skip, limit } = obj;
  delete obj.id;
  delete obj.skip;
  delete obj.limit;

  return new Promise(async (resolve, reject) => {
    const { result, error } = await postRequest(`/admin/user-subscription-history/${id}?skip=${skip}&limit=${limit}`, obj);
    const data = result.result;
    if (!error) {
      resolve({ data, error });
    } else {
      reject({ data, error });
    }
  });
};
